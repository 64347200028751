import"core-js/modules/es.object.to-string.js";import"core-js/modules/es.array.includes.js";import"core-js/modules/es.string.includes.js";import"core-js/modules/es.object.assign.js";import"core-js/modules/es.regexp.exec.js";/* eslint sort-keys: "error" */import{List as ImmutableList}from'immutable';import{createSelector}from'reselect';import semverCoerce from'semver/functions/coerce';import gte from'semver/functions/gte';import semverParse from'semver/functions/parse';import{custom}from'soapbox/custom';/** Import custom overrides, if exists */var overrides=custom('features');/** Truthy array convenience function */var any=function any(arr){return arr.some(Boolean);};/**
 * Friendica, decentralized social platform implementing multiple federation protocols.
 * @see {@link https://friendi.ca/}
 */export var FRIENDICA='Friendica';/**
 * Mastodon, the software upon which this is all based.
 * @see {@link https://joinmastodon.org/}
 */export var MASTODON='Mastodon';/**
 * Mitra, a Rust backend with deep Ethereum integrations.
 * @see {@link https://codeberg.org/silverpill/mitra}
 */export var MITRA='Mitra';/**
 * Pixelfed, a federated image sharing platform.
 * @see {@link https://pixelfed.org/}
 */export var PIXELFED='Pixelfed';/**
 * Pleroma, a feature-rich alternative written in Elixir.
 * @see {@link https://pleroma.social/}
 */export var PLEROMA='Pleroma';/**
 * Takahē, backend with support for serving multiple domains.
 * @see {@link https://jointakahe.org/}
 */export var TAKAHE='Takahe';/**
 * Truth Social, the Mastodon fork powering truthsocial.com
 * @see {@link https://help.truthsocial.com/legal/open-source}
 */export var TRUTHSOCIAL='TruthSocial';/**
 * Wildebeest, backend running on top of Cloudflare Pages.
 */export var WILDEBEEST='Wildebeest';/**
 * Akkoma, a Pleroma fork.
 * @see {@link https://akkoma.dev/AkkomaGang/akkoma}
 */export var AKKOMA='akkoma';/**
 * glitch-soc, fork of Mastodon with a number of experimental features.
 * @see {@link https://glitch-soc.github.io/docs/}
 */export var GLITCH='glitch';/**
 * Rebased, the recommended backend for Soapbox.
 * @see {@link https://gitlab.com/soapbox-pub/rebased}
 */ // NOTE: Rebased is named 'soapbox' for legacy reasons.
export var REBASED='soapbox';/** Backend name reserved only for tests. */ /** eslint-disable-next-line @typescript-eslint/no-unused-vars */export var UNRELEASED='unreleased';/** Parse features for the given instance */var getInstanceFeatures=function getInstanceFeatures(instance){var v=parseVersion(instance.version);var features=instance.pleroma.getIn(['metadata','features'],ImmutableList());return{/**
     * Ability to set one's location on their profile.
     * @see PATCH /api/v1/accounts/update_credentials
     */accountLocation:v.software===TRUTHSOCIAL,/**
     * Look up an account by the acct.
     * @see GET /api/v1/accounts/lookup
     */accountLookup:v.software===TRUTHSOCIAL,/**
     * Ability to subscribe to notifications every time an account posts.
     * @see POST /api/v1/pleroma/accounts/:id/subscribe
     * @see POST /api/v1/pleroma/accounts/:id/unsubscribe
     */accountSubscriptions:v.software===TRUTHSOCIAL,/**
     * Ability to set one's website on their profile.
     * @see PATCH /api/v1/accounts/update_credentials
     */accountWebsite:v.software===TRUTHSOCIAL,/**
     * Google Ads
     */adsV4:true,/**
     * Set your birthday and view upcoming birthdays.
     * @see GET /api/v1/pleroma/birthdays
     * @see POST /api/v1/accounts
     * @see PATCH /api/v1/accounts/update_credentials
     */birthdays:v.software===PLEROMA&&v.build===REBASED&&gte(v.version,'2.4.50'),/**
     * Whether to show the Feed Carousel for suggested Statuses.
     * @see GET /api/v1/truth/carousels/avatars
     * @see GET /api/v1/truth/carousels/suggestions
     */carousel:v.software===TRUTHSOCIAL,/**
     * Ability to mark a carousel avatar as "seen."
     * @see POST /api/v1/truth/carousels/avatars/seen
     */carouselSeen:v.software===TRUTHSOCIAL,/**
     * Ability to accept a chat.
     * POST /api/v1/pleroma/chats/:id/accept
     */chatAcceptance:v.software===TRUTHSOCIAL,/**
     * Ability to add reactions to chat messages.
     */chatEmojiReactions:v.software===TRUTHSOCIAL,/**
     * Pleroma chats API.
     * @see {@link https://docs.pleroma.social/backend/development/API/chats/}
     */chats:v.software===TRUTHSOCIAL,/**
     * Ability to delete a chat.
     * @see DELETE /api/v1/pleroma/chats/:id
     */chatsDelete:v.software===TRUTHSOCIAL,/**
     * Ability to set disappearing messages on chats.
     * @see PATCH /api/v1/pleroma/chats/:id
     */chatsExpiration:v.software===TRUTHSOCIAL,/**
     * Whether chat messages can accept a `media_id` attachment.
     * @see POST /api/v1/pleroma/chats/:id/messages
     */chatsMedia:v.software===TRUTHSOCIAL,/**
     * Whether chat messages have read receipts.
     * @see GET /api/v1/pleroma/chats/:id/messages
     */chatsReadReceipts:v.software===TRUTHSOCIAL,/**
     * Ability to search among chats.
     * @see GET /api/v1/pleroma/chats
     */chatsSearch:v.software===TRUTHSOCIAL,/**
     * Ability to only chat with people that follow you.
     */chatsWithFollowers:v.software===TRUTHSOCIAL,/**
    * Discover page
    */discover:v.software===TRUTHSOCIAL,/**
    * Safe drafts
    */drafts:v.build===UNRELEASED,/**
     * Ability to edit profile information.
     * @see PATCH /api/v1/accounts/update_credentials
     */editProfile:v.software===TRUTHSOCIAL,/**
     * Ability to embed posts on external sites.
     * @see GET /api/oembed
     */embeds:v.software===TRUTHSOCIAL,/**
     * Ability to create and perform actions on events.
     * @see POST /api/v1/pleroma/events
     * @see GET /api/v1/pleroma/events/joined_events
     * @see PUT /api/v1/pleroma/events/:id
     * @see GET /api/v1/pleroma/events/:id/participations
     * @see GET /api/v1/pleroma/events/:id/participation_requests
     * @see POST /api/v1/pleroma/events/:id/participation_requests/:participant_id/authorize
     * @see POST /api/v1/pleroma/events/:id/participation_requests/:participant_id/reject
     * @see POST /api/v1/pleroma/events/:id/join
     * @see POST /api/v1/pleroma/events/:id/leave
     * @see GET /api/v1/pleroma/events/:id/ics
     * @see GET /api/v1/pleroma/search/location
     */events:features.includes('events'),/**
     * Ability to address recipients of a status explicitly (with `to`).
     * @see POST /api/v1/statuses
     */explicitAddressing:any([v.software===PLEROMA&&gte(v.version,'1.0.0'),v.software===TRUTHSOCIAL]),/** Whether the accounts who favourited or emoji-reacted to a status can be viewed through the API. */exposableReactions:any([v.software===FRIENDICA,v.software===MASTODON,v.software===TAKAHE&&gte(v.version,'0.6.1'),v.software===TRUTHSOCIAL,features.includes('exposable_reactions')]),/** Feeds feature */feeds:v.software===TRUTHSOCIAL,// && v.build === UNRELEASED,
/**
     * Groups.
     * @see POST /api/v1/groups
     * @see GET /api/v1/groups
     * @see GET /api/v1/groups/:id
     * @see POST /api/v1/groups/:id/join
     * @see POST /api/v1/groups/:id/leave
     * @see GET /api/v1/groups/:id/memberships
     * @see PUT /api/v1/groups/:group_id
     * @see DELETE /api/v1/groups/:group_id
     * @see GET /api/v1/groups/:group_id/membership_requests
     * @see POST /api/v1/groups/:group_id/membership_requests/:account_id/authorize
     * @see POST /api/v1/groups/:group_id/membership_requests/:account_id/reject
     * @see DELETE /api/v1/groups/:group_id/statuses/:id
     * @see POST /api/v1/groups/:group_id/kick?account_ids[]=…
     * @see GET /api/v1/groups/:group_id/blocks
     * @see POST /api/v1/groups/:group_id/blocks?account_ids[]=…
     * @see DELETE /api/v1/groups/:group_id/blocks?account_ids[]=…
     * @see POST /api/v1/groups/:group_id/promote?role=new_role&account_ids[]=…
     * @see POST /api/v1/groups/:group_id/demote?role=new_role&account_ids[]=…
     * @see GET /api/v1/admin/groups
     * @see GET /api/v1/admin/groups/:group_id
     * @see POST /api/v1/admin/groups/:group_id/suspend
     * @see POST /api/v1/admin/groups/:group_id/unsuspend
     * @see DELETE /api/v1/admin/groups/:group_id
     */groups:v.software===TRUTHSOCIAL,/**
     * Cap # of Group Admins to 5
     */groupsAdminMax:v.software===TRUTHSOCIAL,/**
     * Can see trending/suggested Groups.
     */groupsDiscovery:v.software===TRUTHSOCIAL,/**
     * Can mute a Group.
     */groupsMuting:v.software===TRUTHSOCIAL,/**
     * Can query pending Group requests.
    */groupsPending:v.software===TRUTHSOCIAL,/**
     * Can pin Truths to Groups.
     */groupsPins:v.software===TRUTHSOCIAL,/**
     * Can search my own groups.
     */groupsSearch:v.software===TRUTHSOCIAL,/**
     * Can see topics for Groups.
     */groupsTags:v.software===TRUTHSOCIAL,/**
     * Can validate group names.
     */groupsValidation:v.software===TRUTHSOCIAL,/**
     * Can view LiveTV
     */liveTV:true,/**
     * Can sign in using username instead of e-mail address.
     */logInWithUsername:v.software===TRUTHSOCIAL,/**
     * Can upload media attachments to statuses.
     * @see POST /api/v1/media
     * @see POST /api/v1/statuses
     */media:true,/**
     * Supports pagination in threads.
     * @see GET /api/v1/statuses/:id/context/ancestors
     * @see GET /api/v1/statuses/:id/context/descendants
     */paginatedContext:v.software===TRUTHSOCIAL,/**
     * Require minimum password requirements.
     * - 8 characters
     * - 1 uppercase
     * - 1 lowercase
     */passwordRequirements:v.software===TRUTHSOCIAL,/**
     * Can add polls to statuses.
     * @see POST /api/v1/statuses
     */polls:v.software===TRUTHSOCIAL,/**
     * Ability to quote posts in statuses.
     * @see POST /api/v1/statuses
     */quotePosts:any([v.software===FRIENDICA&&gte(v.version,'2023.3.0'),v.software===PLEROMA&&[REBASED,AKKOMA].includes(v.build)&&gte(v.version,'2.4.50'),features.includes('quote_posting'),instance.feature_quote===true]),/**
     * Ability to report chat messages.
     * @see POST /api/v1/reports
     */reportChats:v.software===TRUTHSOCIAL,/**
     * Can request a password reset email through the API.
     * @see POST /auth/password
     */resetPassword:v.software===PLEROMA,/**
     * Ability to post statuses in Markdown, BBCode, and HTML.
     * @see POST /api/v1/statuses
     */richText:any([v.software===MASTODON&&v.build===GLITCH,v.software===PLEROMA]),/**
     * Ability to follow account feeds using RSS.
     */rssFeeds:any([v.software===MASTODON,v.software===PLEROMA]),/**
     * Ability to manage account security settings.
     * @see POST /api/pleroma/change_password
     * @see POST /api/pleroma/change_email
     * @see POST /api/pleroma/delete_account
     */security:v.software===TRUTHSOCIAL,/**
     * Ability to manage account sessions.
     * @see GET /api/oauth_tokens.json
     * @see DELETE /api/oauth_tokens/:id
     */sessions:v.software===TRUTHSOCIAL,/**
     * Can store client settings in the database.
     * @see PATCH /api/v1/accounts/update_credentials
     */settingsStore:v.software===TRUTHSOCIAL,/**
     * Can display suggested accounts.
     * @see {@link https://docs.joinmastodon.org/methods/suggestions/}
     */suggestions:v.software===TRUTHSOCIAL,/**
     * Supports V2 suggested accounts.
     * @see GET /api/v2/suggestions
     */suggestionsV2:v.software===TRUTHSOCIAL,/**
     * Truth Social trending statuses API.
     * @see GET /api/v1/truth/trending/truths
     */trendingTruths:v.software===TRUTHSOCIAL,/**
     * Can display trending hashtags.
     * @see GET /api/v1/trends
     */trends:v.software===TRUTHSOCIAL,/**
     * Truth Social policies.
     * @see GET /api/v1/truth/policies/pending
     * @see PATCH /api/v1/truth/policies/:policyId/accept
     */truthPolicies:v.software===TRUTHSOCIAL,/**
     * Supports Truth suggestions.
     */truthSuggestions:v.software===TRUTHSOCIAL,/**
     * Truth Social context.
     * @see GET /api/v2/statuses/:id/context/ancestors
     * @see GET /api/v2/statuses/:id/context/descendants
     */truthThread:v.software===TRUTHSOCIAL};};/** Features available from a backend */ /** Detect backend features to conditionally render elements */export var getFeatures=createSelector([function(instance){return instance;}],function(instance){var features=getInstanceFeatures(instance);return Object.assign(features,overrides);});/** Fediverse backend */ /** Get information about the software from its version string */export var parseVersion=function parseVersion(version){var regex=/^([\w+.]*)(?: \(compatible; ([\w]*) (.*)\))?$/;var match=regex.exec(version);var semverString=match&&(match[3]||match[1]);var semver=match?semverParse(semverString)||semverCoerce(semverString,{loose:true}):null;var compat=match?semverParse(match[1])||semverCoerce(match[1]):null;if(match&&semver&&compat){return{build:semver.build[0],compatVersion:compat.version,software:match[2]||MASTODON,version:semver.version};}else{// If we can't parse the version, this is a new and exotic backend.
// Fall back to minimal featureset.
return{build:null,compatVersion:'0.0.0',software:null,version:'0.0.0'};}};